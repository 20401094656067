/* src/components/EditCourse.css */
.edit-course {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .edit-course-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  