/* src/components/StatsSection.css */
.stats-section {
    background-color: #f8f9fa; /* Secondary Light Gray */
    padding: 40px 0;
    text-align: center;
  }
  
  .stat-item {
    margin-bottom: 20px;
  }
  
  .stat-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff; /* Primary Blue */
  }
  
  .stat-label {
    font-size: 1rem;
    color: #6c757d; /* Bootstrap gray */
  }
  