/* src/components/PopularTopics.css */
.popular-topics {
    padding: 60px 0;
    text-align: center;
  }
  
  .popular-topics-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .card {
    width: 300px;
    margin: 15px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card svg {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-description {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .pagination-dots {
    display: none; /* Hide by default */
  }
  
  @media (max-width: 768px) {
    .cards-container {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
    }
  
    .card {
      scroll-snap-align: center;
      flex: 0 0 auto;
    }
  
    .pagination-dots {
      display: block;
      text-align: center;
      margin-top: 20px;
    }
  
    .dot {
      height: 12px;
      width: 12px;
      margin: 0 5px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }
  
    .active {
      background-color: #717171;
    }
  }
  
  @media (min-width: 769px) {
    .card {
      display: block !important; /* Ensure all cards are displayed */
    }
  
    .pagination-dots {
      display: none; /* Hide pagination dots on larger screens */
    }
  }

  

  /* Add these to src/components/PopularTopics.css */
.tech-icon {
    background: url('../images/svg6.svg') no-repeat center center / contain;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
  
  .language-icon {
    background: url('../images/svg5.svg') no-repeat center center / contain;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
  
  .science-icon {
    background: url('../images/svg1.svg') no-repeat center center / contain;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
  
  .health-icon {
    background: url('../images/svg2.svg') no-repeat center center / contain;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
  