.course-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.course-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-content {
  margin-bottom: 20px;
}

/* PDF Viewer Styling */
.pdf-viewer-container {
  width: 100%;
  height: auto;
}

.pdf-viewer-container iframe, .pdf-viewer-container video {
  width: 100%;
  height: 600px; /* You can adjust this height if needed */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .pdf-viewer-container iframe, .pdf-viewer-container video {
    width: 100%;
    height: 400px; /* Adjust for mobile */
  }
}

/* Add next/previous buttons at the bottom */
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
