/* src/components/ReachOutSection.css */
.reach-out-section {
    background-color: #f8f9fa; /* Secondary Light Gray */
    padding: 60px 0;
    text-align: center;
  }
  
  .section-title {
    color: #007bff; /* Primary Blue */
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .reach-out-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  
  .form-control:focus {
    border-color: #007bff; /* Primary Blue */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .btn-primary {
    background-color: #007bff; /* Primary Blue */
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
    border-color: #0056b3;
  }
  