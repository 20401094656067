/* src/components/FeedbackSection.css */
.feedback-section {
    background-color: #ffffff; /* White */
    padding: 60px 0;
    text-align: center;
  }
  
  .section-title {
    color: #007bff; /* Primary Blue */
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .feedback-item {
    margin-bottom: 40px;
  }
  
  .feedback-avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .feedback-comment {
    font-size: 1.1rem;
    font-style: italic;
    margin-bottom: 10px;
    color: #6c757d; /* Bootstrap gray */
  }
  
  .feedback-name {
    font-size: 1rem;
    color: #343a40; /* Bootstrap dark */
  }
  

  .spanlink {
    text-decoration: underline;
  }