/* src/components/CallToActionSection.css */
.call-to-action-section {
    background-color: #007bff; /* Primary Blue */
    color: #ffffff; /* White */
    padding: 60px 0;
    text-align: center;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .cta-text {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    font-size: 1.25rem;
    padding: 10px 20px;
    border-radius: 5px;
  }
  

  .btn-orange {
    color:#007bff;
  }