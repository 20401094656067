.chat-room {
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
}

.chat-messages {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-message {
  padding: 12px;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.chat-message strong {
  color: #333;
  font-size: 14px;
  margin-bottom: 4px;
}

.timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
  text-align: right;
}

.chat-form {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 8px;
}

.chat-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
}

.send-button {
  padding: 12px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.send-button:hover {
  background-color: #0056b3;
}

.send-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
}
