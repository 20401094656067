/* src/components/CourseDetails.css */
.course-details {
  padding: 20px;
  margin: 0 auto;
}

.course-header {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.course-header h2 {
  margin-top: 0;
}

.course-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .course-details {
    padding: 10px;
  }

  .course-header {
    padding: 15px;
  }

  .course-content {
    padding: 15px;
  }
}


/* src/components/CourseDetails.css */

.course-page {
  position: relative;
  padding: 20px;
}

.video-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

/* Ensure the course content remains interactive */
.course-content {
  z-index: 1;
  position: relative;
}


.course-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.share-button:hover {
  background-color: #0056b3;
}

/* Add any custom styles for the PDF viewer container */
.course-page canvas {
  border: 1px solid #ccc;
  display: block;
  margin: 20px auto;
}


/* Container for the entire PDF viewer */
.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f9f9f9;
}

/* Controls (Previous, Next buttons and page info) */
.pdf-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.pdf-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.pdf-controls button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pdf-controls span {
  font-size: 16px;
  color: #333;
}

/* Canvas for rendering the PDF */
.pdf-canvas {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
