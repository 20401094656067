/* src/components/ManageCourses.css */
.manage-courses {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.course-item {
  display: flex;
  flex-direction: column; /* Changed to column to stack course info and actions */
  align-items: flex-start; /* Align items to the start for better readability */
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #f8f9fa; /* Added a background color for better visibility */
}

.course-info {
  margin-bottom: 10px; /* Add space between course info and actions */
  width: 100%; /* Ensure course info takes full width */
}

.course-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 10px; /* Add space between actions and video upload */
}

.video-upload {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; /* Ensure the upload section takes full width */
  padding-top: 10px;
  border-top: 1px solid #ced4da; /* Separate the upload section with a border */
  margin-top: 10px; /* Add space above the upload section */
}

.video-upload input[type="file"] {
  display: block;
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.video-upload button {
  align-self: flex-start; /* Align the upload button to the start */
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.video-upload button:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}
