/* src/components/AdminDashboard.css */
.admin-dashboard {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.admin-dashboard nav ul {
  list-style: none;
  padding: 0;
}

.admin-dashboard nav ul li {
  margin-bottom: 10px;
}

.admin-dashboard nav ul li a {
  text-decoration: none;
  color: #fff;
}

.admin-dashboard nav ul li a:hover {
  text-decoration: underline;
}


.admin-link {
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.admin-link:hover {
  background-color: #0056b3;
}