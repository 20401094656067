/* src/components/HeroSection.css */
.hero-section {
    background-color: #f8f9fa; /* Modify this to your preferred white shade */
    padding: 60px 0;
    text-align: center;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 40px;
  }
  
  .pop {
    color: #fd7e14;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
  }
  
  .search-bar input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px 0 0 5px;
    outline: none;
  }
  
  .search-bar button {
    padding: 10px 20px;
    border: 1px solid #ced4da;
    background-color: #007bff;
    color: white;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .search-bar button:hover {
    background-color: #0056b3;
  }
  