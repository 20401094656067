/* src/components/PrivacyPolicy.css */

.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    margin-top: 20px;
    color: #0056b3;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  