/* src/components/Footer.css */
.footer {
    background-color: #343a40; /* Bootstrap dark */
    color: #ffffff; /* White */
    padding: 40px 0;
  }
  
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-section h5 {
    font-size: 1.25rem;
    margin-bottom: 15px;
  }
  
  .footer-section p {
    font-size: 0.9rem;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #ffffff; /* White */
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .social-icons a {
    color: #ffffff; /* White */
    font-size: 1.5rem;
    margin-right: 15px;
    display: inline-block;
  }
  
  .social-icons a:hover {
    color: #007bff; /* Primary Blue */
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 0.9rem;
  }
  