/* src/components/Navbar.css */
.navbar-transparent {
    background-color: transparent;
  }
  
  .navbar-transparent .navbar-nav .nav-link {
    color: #000; /* Adjust color as needed */
  }
  
  .navbar-transparent .navbar-brand {
    color: #000; /* Adjust color as needed */
  }
  
  .navbar-transparent .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-transparent .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-transparent .btn-outline-primary {
    color: #000; /* Adjust color as needed */
    border-color: #000; /* Adjust color as needed */
  }
  
  .navbar-transparent .btn-outline-primary:hover {
    color: #fff;
    background-color: #000; /* Adjust color as needed */
    border-color: #000; /* Adjust color as needed */
  }

  .pop{
    color: #fd7e14;
  }
  